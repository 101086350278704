.initials-avatar {
    width: 40px;
    height: 40px;
    cursor: pointer;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
    border-top-right-radius: 50%;
    border-top-left-radius: 50%;
    background-color: #F2C425 !important;
    display: inline-block;
    box-sizing: border-box
}

.initials-avatar div {
    color: #fff;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-style: inherit;
    font-weight: inherit
}